import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide7/image/imgPortadaGuia7.png"
import img1 from "@components/pageGuide/guides/guide7/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide7/image/slider1.png"
import slider2 from "@components/pageGuide/guides/guide7/image/slider2.png"
import slider3 from "@components/pageGuide/guides/guide7/image/slider3.jpg"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      Guía para preparar a tu negocio para las ventas de verano
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      El comportamiento de usuarios durante las épocas de verano, y la actual
      desaceleración económica a nivel mundial, han impactado fuertemente en las
      campañas de veranos de todas las empresas. Sin embargo, aún se pueden
      aplicar estrategias claves para revertir esta problemática.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Las ofertas no lo serán todo en una campaña de verano, sino también algo
    mucho más valioso como la atención al cliente que una empresa puede ofrecer.
    De este modo, se estará añadiendo un valor agregado único que marca
    diferencia dentro de la competencia.
    <br /> <br />
    Por eso, en esta guía aprenderás a conocer mejor a tus clientes para que
    puedas crear mensajes y ofertas más personalizadas. Además, entenderás cómo
    aplicar la omnicanalidad dentro de tu empresa para optimizar tus canales de
    atención.
  </p>
)

const data = {
  start: {
    support: "Guía | Ecommerce",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Análisis del recorrido del cliente",
      },
      {
        key: 2,
        text: "Ideas para la creación de contenido",
      },
      {
        key: 3,
        text: "Definición de propósitos en tus canales de atención",
      },
      {
        key: 4,
        text: "Creación de un enfoque omnicanal",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
